body.current-show-single-image-lightbox .lb-button.lb-icon-arrow.next,
body.current-show-single-image-lightbox .lb-button.lb-icon-arrow.prev {
  display: none;
}

.temp-lb-container {
  display: none;
}

.temp-lb-container .lb-header {
  content: "&nbsp;";
}

body.on-switch-image-lightbox .temp-lb-container {
  display: flex;
}
